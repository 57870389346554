import { isServer } from "solid-js/web";

export type GlobalConfig = {
  environmentStage: string;
  isCaptchaEnforced: boolean;
  customerIO: {
    trackAPI: {
      siteID: string;
    };
  };
  captchaFox: {
    siteIDKey: string;
  };
};

function getEnv(key: string) {
  const env = isServer ? process.env : import.meta.env;
  const val = env[key];
  if (!env) {
    throw new Error(`Could not get env val for key ${key}`);
  }
  return val;
}

export function getGlobalConfig(): GlobalConfig {
  return {
    environmentStage: getEnv("VITE_ENVIRONMENT_STAGE"),
    isCaptchaEnforced: getEnv("VITE_CAPTCHA_ENFORCED") === "True",
    customerIO: {
      trackAPI: {
        siteID: getEnv("VITE_CUSTOMER_IO_TRACK_API_SITE_ID"),
      },
    },
    captchaFox: {
      siteIDKey: getEnv("VITE_CAPTCHAFOX_SITE_KEY"),
    },
  };
}
